var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"html-dom-form-framework"}},[_c('div',{ref:"1",staticStyle:{"margin-bottom":"12px"},attrs:{"id":"home-page-slider-container"}},[_c('carousel',{attrs:{"perPage":1,"paginationActiveColor":"#EC706D","autoplay":true}},_vm._l((_vm.bannerItems),function(item,index){return _c('slide',[_c('a',{attrs:{"href":item.href}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.image,"alt":("" + (_vm.$t('event.littleMuseumKol')))}})])])}),1),_c('div',{staticClass:"container inner-container",staticStyle:{"margin-top":"30px","margin-bottom":"100px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3 col-md-6 col-sm-12 section home-item",on:{"click":function($event){return _vm.goToPage('news')}}},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.highlights")))]),_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-1-desktop.png') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.highlights')))}}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-1-mobile.png') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.highlights')))}})]),_c('div',{staticClass:"col-12 col-lg-3 col-md-6 col-sm-12 section home-item",on:{"click":function($event){return _vm.goToPage('kol-video')}}},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.kolVid")))]),_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/kolvid-img.png') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.kolVid')))}}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/kolvid-img.png') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.kolVid')))}})]),_c('div',{staticClass:"col-12 col-lg-3 col-md-6 col-sm-12 section home-item",on:{"click":function($event){return _vm.goToPage('facebookPosts')}}},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.KolSharing")))]),_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-3-desktop.jpg') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.KolSharing')))}}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-3-mobile.jpg') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.KolSharing')))}})]),_c('div',{staticClass:"col-12 col-lg-3 col-md-6 col-sm-12 section home-item",on:{"click":function($event){return _vm.goToPage('bloopers')}}},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.bloopers")))]),_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-4-desktop.jpg') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.bloopers')))}}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
                backgroundImage:
                  'url(' + require('@/assets/images/home-4-mobile.jpg') + ')',
              }),attrs:{"alt":("" + (_vm.$t('header.bloopers')))}})])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }