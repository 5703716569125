<template>
  <div>
    <div id="html-dom-form-framework">
      <div id="home-page-slider-container" style="margin-bottom: 12px" ref="1">
        <carousel :perPage="1" paginationActiveColor="#EC706D" :autoplay="true">
          <slide v-for="(item, index) in bannerItems">
            <a :href="item.href">
              <img :src="item.image" :alt="`${$t('event.littleMuseumKol')}`" style="width: 100%;" />
            </a>
          </slide>
        </carousel>

        <!--
                <div class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item text">
                    <div class="section-title">{{$t("header.onlineExhibition")}}</div>
                </div>
                -->
        <div class="container inner-container" style="margin-top: 30px; margin-bottom: 100px;">
          <div class="row">
            <div class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item" @click="goToPage('news')">
              <div class="section-title">{{ $t("header.highlights") }}</div>
              <div
                  class="img-fluid home-img d-none d-lg-block"
                  :alt="`${$t('header.highlights')}`"
                  :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-1-desktop.png') + ')',
                }"
              />
              <div
                  class="img-fluid home-img d-block d-lg-none"
                  :alt="`${$t('header.highlights')}`"
                  :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-1-mobile.png') + ')',
                }"
              />
            </div>

            <div class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item" @click="goToPage('kol-video')">
              <div class="section-title">{{ $t("header.kolVid") }}</div>
              <div
                class="img-fluid home-img d-none d-lg-block"
                :alt="`${$t('header.kolVid')}`"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/kolvid-img.png') + ')',
                }"
              />
              <div
                class="img-fluid home-img d-block d-lg-none"
                :alt="`${$t('header.kolVid')}`"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/kolvid-img.png') + ')',
                }"
              />
            </div>
            <!--
                  <div class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item" @click="goToPage('online-exhibition')">
                      <div
                          class="img-fluid home-img d-none d-lg-block"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/home-2-desktop.jpg') + ')' }"
                      />
                      <div
                          class="img-fluid home-img d-block d-lg-none"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/home-2-mobile.jpg') + ')' }"
                      />
                  </div>
              -->

            <div
              class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item"
              @click="goToPage('facebookPosts')"
            >
              <div class="section-title">{{ $t("header.KolSharing") }}</div>
              <div
                  class="img-fluid home-img d-none d-lg-block"
                  :alt="`${$t('header.KolSharing')}`"
                  :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-3-desktop.jpg') + ')',
                }"
              />
              <div
                class="img-fluid home-img d-block d-lg-none"
                :alt="`${$t('header.KolSharing')}`"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-3-mobile.jpg') + ')',
                }"
              />
            </div>
            <div class="col-12 col-lg-3 col-md-6 col-sm-12 section home-item" @click="goToPage('bloopers')">
              <div class="section-title">{{ $t("header.bloopers") }}</div>
              <div
                class="img-fluid home-img d-none d-lg-block"
                :alt="`${$t('header.bloopers')}`"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-4-desktop.jpg') + ')',
                }"
              />
              <div
                class="img-fluid home-img d-block d-lg-none"
                :alt="`${$t('header.bloopers')}`"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/images/home-4-mobile.jpg') + ')',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import axios from "axios";

export default {
  name: "home",
  title: "小小博物館 KOL",
  data: function() {
    return {
      bannerItems: [],
    };
  },
  methods: {
    goToPage(page) {
      switch (page) {
        case "news":
          this.$router.push("news");
          break;
        case "online-exhibition":
          this.$router.push("online-exhibition");
          break;
        case "facebookPosts":
          this.$router.push("kol-sharing");
          break;
        case "kol-video":
          this.$router.push("kol-video");
          break;
        case "bloopers":
          this.$router.push("event-highlights");
          break;
      }
    },
    getBannerItems: function() {
      var vue = this;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/banner-components/banner-render-data-v2.php?lang=${locale}`,
      })
        .then((response) => {
          console.log(response.data);
          vue.bannerItems = response.data.map((item) => {
            return {
              image: vue.$S3_BUCKET_URL + "/" + item.imgPath,
              href:
                item.href != null
                  ? item.href.substring(0, 7) === "http://" ||
                    item.href.substring(0, 8) === "https://"
                    ? item.href
                    : `${location.protocol}//${item.href}`
                  : "#",
            };
          });
        })
        .catch(function(error) {
          console.error("erros : ", error);
        });
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mounted: function() {
    this.getBannerItems();
  },
  watch: {
    "$i18n.locale": function() {
      this.getBannerItems();
    },
  },
};
</script>
<style scoped>
#home-page-slider-container{position: relative; top: -150px; z-index: 1;}
.banner {
  background-color: rgb(255, 253, 238);
  width: 100%;
  max-height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  max-height: 1000px;
  height: 206px;
}
@media (min-width: 992px) {
  #html-dom-form-framework {
    margin-top: 20px;
  }

}
@media (min-width: 768px) {
  .banner {
    height: 360px;
  }
}
@media (min-width: 1140px) {
  .banner {
    height: 420px;
  }
}
.home-img {
  height: auto !important;
  aspect-ratio: 2.01;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}
@media (min-width: 992px) {
  .home-img {
    padding-bottom: 0px;
  }
}
.section .section-title {
  color: #EC706D;
  font-size: 28px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
  font-weight: bold;
}
.section .img-fluid{
  position: relative;display: block;;
}
.section .img-fluid:before {
  position: absolute;content:"";width: 100%;height: 100%;display: block;background: rgba(0,0,0,.5);
  opacity: 0;
  transition: opacity ease-in-out .5s;
}
.section .section-title:before {
  content: "";
  position: absolute;
  width:0%;
  transition: width ease-in-out .5s;
  height: 3px;
  left:0;bottom:0;
  background: #EC706D;
  display: block;;
}
.section:hover .section-title:before{
  width: 100%;
}
.section:hover .img-fluid:before{
  opacity: 1;
}
@media (max-width: 576px) {
  .section .section-title {
    font-size: 20px;
  }
}
.section {
  margin-bottom: 20px;

}
div.home-item {
  cursor: pointer;
}

#html-dom-form-framework{margin-top:0 !important;}
@media (max-width:1200px){
  .section .section-title{font-size:22px;}
}

@media (max-width: 992px) {
  div.home-item.text {
    margin-top: 24px;
  }
  #home-page-slider-container{
    position: static;
  }
  .section .section-title{font-size:28px;}
}
@media (max-width: 780px) {
  div.home-item{
    margin-bottom: 50px;
  }
  .section .section-title{font-size:22px;}
}
</style>
<style>
.VueCarousel-dot-container {
  margin-top: 12px !important;
}
.VueCarousel-dot {
  margin-top: 0px !important;
}
@media (prefers-reduced-motion) {
  /* styles to apply if a user's device settings are set to reduced motion */
  .section .img-fluid:before{
    transition: none;
  }
  .section .section-title:before{
    transition: none;
  }
}

@media (max-width: 1600px) {
  html[lang="en"] #vue-nav-links-desktop{padding-left:250px;}
  #vue-nav-links-desktop .item{padding: 0 5px;}
  #vue-nav-links-desktop .item{letter-spacing: -1px;}
}
@media (max-width: 1500px) {
  #home-page-slider-container div.home-item{letter-spacing: -2px;}
}
@media (max-width: 1080px) {

}
@media (max-width: 960px) {
  #home-page-slider-container div.home-item{letter-spacing: 0px;}
}
</style>
