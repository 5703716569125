<template>
  <div>
    <div class="container">
      <div class="row" id="fb-header">
        <div class="col-12 col-lg-6 col-xl-8 center-text">
          {{ $t("facebookPosts.description") }}
        </div>
        <div class="col-12 col-lg-6 col-xl-4" style="text-align: center;">
          <a href="https://www.facebook.com/groups/244756710882712"
             style="display: block;width: 100%;"
             :aria-label="`${$t('header.KolSharing')}`"
            ><img :src="getFbGroupPicUrl()" style="width: 100%;" :alt="`${$t('header.KolSharing')}`"
          /></a>
        </div>
      </div>
      <div>
        <div id="desktop-header" class="container d-none d-lg-block">
          <div class="title">
            {{ $t("header.KolSharing") }}
          </div>
        </div>
        <div id="mobile-header" class="d-block d-lg-none">
          <div class="title">
            {{ $t("header.KolSharing") }}
          </div>
        </div>
      </div>
      <div class="text-center d-lg-none d-md-none d-sm-block">
        <select class="year-tab-selector" @change="switchTab($event.target.value)">
          <option :selected="this.year === '2021'" value="2021">2021</option>
          <option :selected="this.year === '2022'" value="2022">2022</option>
          <option :selected="this.year === '2023'" value="2023">2023</option>
          <option :selected="this.year === '2024'" value="2024">2024</option>
        </select>
      </div>
      <div class="year-tab d-lg-block d-md-block d-sm-none d-none">
        <strong
          @click="switchTab('2021')"
          :class="{ selected: this.year === '2021' }"
          >2021</strong
        >
        <span>|</span>
        <strong
          @click="switchTab('2022')"
          :class="{ selected: this.year === '2022' }"
          >2022</strong
        >
        <span>|</span>
        <strong
          @click="switchTab('2023')"
          :class="{ selected: this.year === '2023' }"
          >2023</strong
        >
        <span>|</span>
        <strong
          @click="switchTab('2024')"
          :class="{ selected: this.year === '2024' }"
          >2024</strong
        >
      </div>

      <!-- <div id="fb-root"></div>
      <div class="fb-like" data-href="https://www.facebook.com/100044385460984/videos/610325163686109" data-width="" data-layout="button" data-action="like" data-size="large" data-share="true"></div> -->
      <div id="html-dom-form-framework">
        <div class="container">
          <div class="masonry" style="padding: 0 15px">
            <div v-if="loading==false" class="masonry-item" :key="index" v-for="(item, index) in urlList">
              <a :href="item.url"
                 :aria-label="`${$t('header.KolSharing')}`"
                ><v-lazy-image
                  :src="item.image"
                  :alt="`${$t('header.KolSharing')}`"
                  loading="lazy"
                  class="facebook-post-img"
                  width="350"
                  height="450"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    VLazyImage
  },
  name: "FacebookPosts",
  title: "小小博物館 KOL",
  data() {
    return {
      year:'2024',
      urlList: [],
      loading:false
    };
  },
  methods: {
    switchTab: function(year) {
      this.urlList = [];
      this.loading = true;
      this.year = year;
      this.getUrlList();
    },
    getUrlList: function() {
      let vue = this;

      // TODO: API to get facebook thumbnail and links
      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/post_components/post-get-embed-code.php?year=${this.year}`,
      }).then((response) => {
        for (const key in response.data) {
          const item = response.data[key];
          if (item.status == "enable" && item.imgPath != null) {
            vue.urlList.push({
              image: `${this.$S3_BUCKET_URL}/` + item.imgPath,
              url: item.embed_code,
            });
          }
        }
        this.loading = false;
      });
    },
    goToPage: function(page) {
      this.page = page;
      this.loadFacebook();
      console.log(page);
    },
    getFbGroupPicUrl: function() {
      return require(`../assets/images/facebook_search_bar_${this.$i18n.locale}.jpg`);
       //return require(`../assets/images/facebook_search_bar.png`);
    },
  },
  mounted: function() {
    this.getUrlList();
    this.$forceUpdate();
  },
};
</script>

<style>
.masonry {
  -moz-column-count: 4;
  -moz-column-gap: 2%;
  -moz-column-width: 23%;
  -webkit-column-count: 4;
  -webkit-column-gap: 2%;
  -webkit-column-width: 23%;
  column-count: 4;
  column-gap: 2%;
  column-width: 23%;
  margin: 24px 0;
  margin-right: 15px;
}

.masonry .masonry-item {
  margin-bottom: 12px;
  text-align: center;
}
.masonry-item a{display: block;}
.masonry-item a img{width:100%;}

@media (max-width: 1200px) {
  .masonry {
    column-count: 3;
  }
}

@media (max-width: 996px) {
  .masonry {
    column-count: 2;
  }
}
@media (max-width: 640px) {
  .masonry {
    column-count: 1;
  }
}

.fb-post > span {
  width: 350px !important;
}

.fb-post iframe {
  overflow: hidden;
  width: 100% !important;
}

.pagination {
  display: inline-block;
}

.pagination span.link {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}

.pagination span.link.active {
  font-weight: bold;
}

#fb-header {
  margin-top: 24px;
  margin-bottom: 32px;
}

div.center-text {
  margin: auto;
  width: 50%;
  padding: 0 15%;
  text-align: center;
  font-size: 28px;
}
.facebook-post-img {
  width: 290px;
  height: auto;
}
.year-tab {
  text-align: center;
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
}
.year-tab span {
  font-size: 2rem;
  margin: 0 12px;
  cursor: pointer;
}
.year-tab span.selected {
  color: #EC706D;
  font-weight: bold;
  text-decoration: underline;
}
.year-tab strong {
  font-size: 2rem;
  margin: 0 12px;
  cursor: pointer;
  font-weight: normal;
}
.year-tab strong.selected {
  color: #EC706D;
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 1200px) {
  div.center-text {
    padding: 0 5%;
  }
}
@media (max-width: 996px) {
  div.center-text {
    margin-bottom: 12px;
  }
  .facebook-post-img {
    width: 100%;
    max-width: 350px;
  }
  .masonry {
    margin-right: 0px;
  }
  div.center-text{font-size:24px;}
}
@media (max-width: 640px) {
  div.center-text{font-size:18px;}
}
@media (max-width: 480px) {
  div.center-text{font-size:16px;}
}
.year-tab-selector{color: #eb706d; background-color: #F6F7FB;font-size: 20px;font-weight: bold;margin-top: 40px;text-align-last:center;width: 50%;margin-bottom: 20px;}
</style>
