<template>
  <div>
    <div id="ordinance" name="ordinance">
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{$t("header.contactUs")}}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{$t("header.contactUs")}}
        </div>
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div class="container normal-text" style="margin-top: 24px">
        {{$t("contactus.text1")}} <a href="mailto:event@ohpama.com">{{$t("contactus.text2")}}</a> {{$t("contactus.text3")}}
      </div> 
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactUs',
    title: '小小博物館 KOL',
  }
</script>

<style scoped>

</style>