<template>
  <div>
    <div id="html-dom-form-framework">
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{ $t(`kolVid.themeTitle${type}`) }}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{ $t(`kolVid.themeTitle${type}`) }}
        </div>
      </div>
      <!--
      <div class="year-tab">
        <span @click="switchTab('2021')" :class="{ selected: this.year === '2021' }">2021</span>
        <span>|</span>
        <span @click="switchTab('2022')" :class="{ selected: this.year === '2022' }">2022</span>
        <span>|</span>
        <span @click="switchTab('2023')" :class="{ selected: this.year === '2023' }">2023</span>
      </div>
      -->
      <div id="bloopers" class="container">
        <div class="nav-section">
          <span
            v-if="$i18n.locale == 'en' && type !== '1'"
            class="nav-button"
            @click="setType('1')"
            ><img src="../assets/images/SEA_EN.png"
                :alt="`${$t('kolVid.themeTitle1')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'en' && type !== '2'"
            class="nav-button"
            @click="setType('2')"
            ><img src="../assets/images/LIFE_EN.png"
                  :alt="`${$t('kolVid.themeTitle2')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'en' && type !== '3'"
            class="nav-button"
            @click="setType('3')"
            ><img src="../assets/images/AST_EN.png"
                  :alt="`${$t('kolVid.themeTitle3')}`"
          /></span>
          <!--TC-->
          <span
            v-if="$i18n.locale == 'tc' && type !== '1'"
            class="nav-button"
            @click="setType('1')"
            ><img src="../assets/images/SEA_TC.png"
                  :alt="`${$t('kolVid.themeTitle1')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'tc' && type !== '2'"
            class="nav-button"
            @click="setType('2')"
            ><img src="../assets/images/LIFE_TC.png"
                  :alt="`${$t('kolVid.themeTitle2')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'tc' && type !== '3'"
            class="nav-button"
            @click="setType('3')"
            ><img src="../assets/images/AST_TC.png"
                  :alt="`${$t('kolVid.themeTitle3')}`"
          /></span>
          <!--SC-->
          <span
            v-if="$i18n.locale == 'sc' && type !== '1'"
            class="nav-button"
            @click="setType('1')"
            ><img src="../assets/images/SEA_SC.png"
                  :alt="`${$t('kolVid.themeTitle1')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'sc' && type !== '2'"
            class="nav-button"
            @click="setType('2')"
            ><img src="../assets/images/LIFE_SC.png"
                  :alt="`${$t('kolVid.themeTitle2')}`"
          /></span>
          <span
            v-if="$i18n.locale == 'sc' && type !== '3'"
            class="nav-button"
            @click="setType('3')"
            ><img src="../assets/images/AST_SC.png"
                  :alt="`${$t('kolVid.themeTitle3')}`"
          /></span>
        </div>
        <div class="row justify-content-md-center">
          <template v-for="(blooper, index) in blooperList">
            <div :key="blooper.id" class="col-12 col-lg-5">
              <router-link
                class="item no-decoration"
                :to="{
                  name: 'KolVidDetails',
                  query: { id: blooper.ref_id, type: type },
                }"
              >
                <div class="section-header">
                  <div class="title">
                    {{ blooper.title }}
                  </div>
                </div>
                <div style="text-align: center">
                  <img
                    class="img-fluid section-image"
                    :src="blooper['cover-img']"
                    :alt='`${blooper.title}`'
                  />
                </div>
                <div class="normal-text section-description">
                  {{ blooper["preview-text"] }}
                </div>
              </router-link>
            </div>
            <div :key="blooper.id" class="col-lg-1" v-if="index % 2 == 0" />
            <div
              class="col-12 d-none d-lg-block"
              :key="blooper.id"
              v-if="index % 2 == 1"
            >
              <hr class="c-hr" />
            </div>
            <div class="col-12 d-block d-lg-none" :key="blooper.id">
              <hr class="c-hr" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Bloopers",
  title: "小小博物館 KOL",
  data() {
    return {
      blooperList: [],
      idList: [],
      year: "2023",
      type: null,
    };
  },
  methods: {
    setType: function(type) {
      this.$router.push({
        name: "KolVid",
        query: {
          type: type,
        },
      });
    },
    switchTab: function(year) {
      this.year = year;
      this.getBloopersList();
    },
    getBloopersList: function() {
      let vue = this;
      vue.blooperList = [];

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      // FIre HTTP Request to CMS Server
      axios({
        method: "get",
        url: `${
          vue.$CMS_API_URL
        }/events_components/fun-facts-overview.php?lang=${locale.toUpperCase()}&year=${
          this.year
        }&type=${this.type}`,
      })
        .then((response) => {
          console.log("response", response.data);
          if (response.data && response.data.data) {
            vue.blooperList = response.data.data.map((blooper) => {
              return Object.assign(blooper, {
                "cover-img": `${vue.$S3_BUCKET_URL}/${blooper["cover-img"]}`,
              });
            });
            console.log("vue.blooperList", vue.blooperList);
            vue.idList = vue.blooperList.map((e) => e.ref_id);
          }
        })
        .catch(function(error) {
          console.log("erros : ", error);
        });
    },
  },
  mounted: function() {
    if (!this.$route.query.type || this.$route.query.type == null) {
      this.$router.push("KolVidLanding");
    }
    this.type = this.$route.query.type;
    this.getBloopersList();
  },
  watch: {
    "$i18n.locale": function() {
      this.getBloopersList();
    },
    "$route.query.type": function() {
      this.type = this.$route.query.type;
      console.log(this.type);
      this.getBloopersList();
    },
  },
};
</script>

<style scoped>
#bloopers {
  margin-top: 40px;
  margin-bottom: 40px;
}
.section-header {
  text-align: center;
  margin-bottom: 48px;
}
.section-header .title {
  padding: 12px;
  background-color: #EC706D;
}
.section-description {
  margin-top: 24px;
}
hr.c-hr {
  border: 4px solid;
  border-color: #ffffff;
  margin: 36px 0px;
}
.year-tab {
  text-align: center;
  margin-top: 40px;
}
.year-tab span {
  font-size: 2rem;
  margin: 0 12px;
  cursor: pointer;
}
.year-tab span.selected {
  color: #EC706D;
  font-weight: bold;
}
@media (max-device-width: 550px) {
  .nav-button {
    max-width: 45%;
  }
  .nav-button img {
    width: 100%!important ;
  }
}
</style>

<style>
.no-decoration,
.no-decoration:hover {
  text-decoration: none;
  color: unset;
}

</style>
