<template>
    <div class="wrap" id="footer">
        <div class="container" style="height: 100%;">
            <div id="footer-upper">

                <img src="../assets/images/lcsd_logo_forweb_white.png" class="footer-main-logo item" :alt="`${$t('footer.lcsd')}`" />

                <span class="item first" @click="goToContactUs()" >{{$t("footer.contactUs")}}</span>
                <span class="item" @click="goToOrdinance()" >{{$t("footer.terms")}}</span>
                <a :href="$t('footer.ordinanceURL')">{{$t("footer.privacy")}}</a>

                <router-link class="item last" :to="{ name: 'Sitemap'}" exact >{{$t("footer.sitemap")}}</router-link>

            </div>
            <div id="footer-lower">
                <span>{{$t("footer.allRightReserved")}}</span>
            </div>
        </div>
    </div>
</template>
 
<script>
    export default {
        name: 'footer',
        methods: {
            goToOrdinance() {
                window.location.href = `/${this.$i18n.locale}/terms-and-condition`;
            },
          goToContactUs() {
            window.location.href = `/${this.$i18n.locale}/contact-us`;
          },
          goToSiteMap() {
            window.location.href = `/${this.$i18n.locale}/sitemap`;
          }
        }
    }
</script>
<style scoped>
    #footer {
        width: 100%;
        height: 82px;
        background-color: #595757;
    }
    #footer .container {
        text-align: center;
        background-color: #595757;
      position: relative;;
    }
    #footer #footer-upper {
        font-size: 16px;

      line-height: 16px;
      padding-top:15px;
      position: absolute;
      top:0;bottom:0;margin-top:auto;margin-bottom: auto;
      left:0;
    }
    @media(max-width: 992px) {
        #footer #footer-upper {
            font-size: 13px;
            text-align: left;
        }
    }
    #footer #footer-lower {
        color:#FFF;
      line-height: 16px;
      height: 16px;
        padding-bottom: 18px;
      position: absolute;
      top:0;bottom:0;margin-top:auto;margin-bottom: auto;
      right:0;
    } 
    #footer #footer-upper a, #footer #footer-upper span {
        color: #ffffff;
        text-decoration: none;
        padding: 0px 10px;
        display: inline; 
        border-left: 2px solid; 
        border-color: #FFFFFF;
        cursor: pointer;
    }
    #footer #footer-upper a.first, #footer #footer-upper span.first  {
        border-left: none; 
        padding-left: 0px;
      margin-left: 20px;
    } 
    img.social-media-img {
        margin-right: 12px;
        cursor: pointer;
    }
    .footer-main-logo{
      width: 150px;margin-top: -15px;margin-left: 50px;
    }
    #footer{height: 100px;padding-top: 15px;margin-top:-15px;}
    #footer span{}
    .social-buttons-footer-container{display:inline-block;}
    #footer-lower{margin-left:50px;margin-right: 50px;}

    @media(max-width: 992px) {
        img.social-media-img {
            margin-right: 8px;
        }
        #footer-lower {
            text-align: left;
        }
      #footer-lower{margin: 0;}
      .footer-main-logo{margin-left:0;margin-right: 0;}
    }
    @media(min-width: 992px) {
        img.social-media-img.first {
            margin-left: 24px
        }
    }
    @media(max-width: 768px) {
      #footer{height:auto;padding-bottom:50px;}
      #footer #footer-upper,#footer #footer-lower{position:static;}
      #footer-upper{text-align: center !important;}
      .footer-main-logo{display:block;margin:auto;margin-bottom:15px;margin-top: 0;}
      #footer-lower{text-align: center;margin-top:15px !important;}
      .social-buttons-footer-container{display: block;text-align: center;margin-top:15px;}

    }
</style>