<template>
    <div v-if="ready">
      <gallery :images="responseData.imgs" :index="index" @close="index = null"></gallery>
      <div class="container">        
        <div>
          <div id="desktop-header" class="container d-none d-lg-block">
              <div class="title">
                  {{$t("header.onlineExhibitionSubTitle")}}
              </div>
          </div>
          <div id="mobile-header" class="d-block d-lg-none">
              <div class="title">
                  {{$t("header.onlineExhibitionSubTitle")}}
              </div>
          </div>
        </div>

        <div class="nav-section" style="margin-top: 40px;">
          <span v-if="$i18n.locale=='en'" class="nav-button" :style="{visibility: isShowPrev(-1) ? 'visible' : 'hidden'}" @click="navigateToExhibition(-1)"><img :alt="`${$t('header.previous')}`" src="../assets/images/prev_en.png"/></span>
          <span v-if="$i18n.locale!=='en'" class="nav-button" :style="{visibility: isShowPrev(-1) ? 'visible' : 'hidden'}" @click="navigateToExhibition(-1)"><img :alt="`${$t('header.previous')}`" src="../assets/images/prev_tc.png"/></span>
          <span v-if="$i18n.locale=='en'" class="nav-button" :style="{visibility: isShowNext(1) ? 'visible' : 'hidden'}" @click="navigateToExhibition(1)"><img :alt="`${$t('header.next')}`" src="../assets/images/next_en.png"/></span>
          <span v-if="$i18n.locale!=='en'" class="nav-button" :style="{visibility: isShowNext(1) ? 'visible' : 'hidden'}" @click="navigateToExhibition(1)"><img :alt="`${$t('header.next')}`" src="../assets/images/next_tc.png"/></span>
        </div>

        <div class="row content offsetWrapper" style="margin-bottom: 48px">
          <div class="col-12 col-lg-6">
            <div class="introduction">
              <div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-name'">
                  {{$t("onlineExhibition.authorName")}}
                </div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-content'">
                  {{ responseData.name }}
                </div>
              </div>
              <div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-name'">
                  {{$t("onlineExhibition.age")}}
                </div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-content'">
                  {{ responseData.age }}
                </div>
              </div>
              <div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-name'">
                  {{$t("onlineExhibition.productName")}}
                </div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-content'">
                  {{ responseData.productName }}
                </div>
              </div>
              <div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-name'">
                  {{$t("onlineExhibition.venue")}}
                </div>
                <div :class="$i18n.locale=='en' ? 'field-name eng_inline':'field-content'">
                  {{ produceFieldMapper(responseData,'name') }}
                </div>
              </div>
            </div>
            <hr class="separator"/>
            <div class="body">
              <div v-html="responseData.description.replace(/\r\n|\r|\n/g,'<br />')"></div>
            </div>
            <hr class="separator"/>
            <div class="audio-wrapper">
              <img v-if="currLocale=='en'" :alt="`${$t('header.audioIntroduction')}`" :src="require('../assets/images/audio_en.png')" class="img-fluid voice-logo toggle-sound paused" @click="toggleSound()"/>
              <img v-if="currLocale=='tc'" :alt="`${$t('header.audioIntroduction')}`" :src="require('../assets/images/audio_tc.png')" class="img-fluid voice-logo toggle-sound paused" @click="toggleSound()"/>
              <img v-if="currLocale=='sc'" :alt="`${$t('header.audioIntroduction')}`" :src="require('../assets/images/audio_sc.png')" class="img-fluid voice-logo toggle-sound paused" @click="toggleSound()"/>
            </div>
            <audio
              controls
              ref="audio"
              :src="mp3FilePath"
              controlsList="nodownload"
              preload
              loop
              id="audio"
            ></audio>
            <div class="remark">
              {{ responseData.remarks }}
            </div>
          </div>
          <div v-if="isMobile==false" class="col-12 col-lg-6">
            <div class="image-container">
              <img :src="responseData.imgs[0]" alt="" @click="index = 0" :alt="`${$t('header.referenceImage')}`">
            </div>
            <div class="image-container">
              <agile 
                :options="options"
                :key="responseData.imgs.length"
                id="agile"
              >
                  <div class="slide" v-if="i>0" v-for="(image, i) in responseData.imgs" :key="i+1" :class="`slide--${i+1}`" @click="index = i">
                      <img :src="image" :alt="`${$t('header.referenceImage')}`">
                  </div>
                  <template slot="prevButton">
                    <img :src="require('../assets/images/arrow-left.png')" class="img-fluid main-logo"
                         :alt="`${$t('header.previous')}`"
                    />
                  </template>
                  <template slot="nextButton">
                    <img :src="require('../assets/images/arrow-right.png')" class="img-fluid main-logo"
                         :alt="`${$t('header.next')}`"
                    />
                  </template>
              </agile>
            </div>
          </div>
          <div v-else class="col-12 col-lg-6">
            <div class="image-container inlineArrow">
              <agile 
                :options="mobileOptions"
                :key="responseData.imgs.length"
                id="agile"
              >
                  <div class="slide mobile" v-for="(image, i) in responseData.imgs" :key="i+1" :class="`slide--${i+1}`" @click="index = i">
                    <img :src="image" :alt="`${$t('header.referenceImage')}`"/>
                  </div>
                  <template slot="prevButton">
                    <img :src="require('../assets/images/arrow-left.png')" class="img-fluid main-logo"
                         :alt="`${$t('header.previous')}`"
                    />
                  </template>
                  <template slot="nextButton">
                    <img :src="require('../assets/images/arrow-right.png')" class="img-fluid main-logo"
                         :alt="`${$t('header.next')}`"
                    />
                  </template>
              </agile>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from 'axios';
  import VueGallery from 'vue-gallery'
  import { VueAgile } from 'vue-agile'
  import MapZoomer from '../components/ZoomWrapper.vue'
  import { isMobile } from '../main'
  export default {
    components:{
      MapZoomer,
      'gallery': VueGallery,
      'agile': VueAgile
    },
    data(){
      return {
        responseData: {},
        id:this.$attrs.id,
        currLang:this.langMapper(),
        index: null,
        showOverlay:false,
        ready:false,
        isMobile:isMobile,
        currLocale:this.$i18n.locale,
        mobileOptions:{
          autoplay: false,
          dots: false,
          navButtons: true,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
                {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 1,
                    }
                }
            ]
        },
        options: {
          autoplay: false,
          dots: false,
          navButtons: true,
          slidesToShow: 3,
          mobileFirst: false,
          responsive: [
                {
                    breakpoint: 996,
                    settings: {
                      slidesToShow: 2,
                    }
                }
            ]
        },
        mp3FilePath: '',
        itemIdList: []
      }
    },
    created(){
      this.getData();
      this.fetchItemList();
    },
    watch: {
      '$i18n.locale': function() {
        this.getData();
      },
      '$route.query.id': function() {
        this.id = this.$route.query.id
        this.getData();
      }
    },
    methods:{
      close(){
        this.showOverlay = false;
      },
      langMapper(){
        var lang;
        switch (this.$i18n.locale) {
            case 'en':
                lang = 'EN';
            break;
            case 'tc':
                lang = 'TC';
            break;
            case 'sc':
                lang = 'SC';
            break;
        }
        return lang;
      },
      produceFieldMapper(data,field){
          var lang = 'en'
          var currLocale = this.$i18n.locale;
          switch (currLocale) {
              case 'en':
                  lang = 'en';
              break;
              case 'tc':
                  lang = 'chi';
              break;
              case 'sc':
                  lang = 'sim';
              break;
          }
          return data[`${field}_${lang}`];
      },
      getData(){
        let vue = this;
        axios.get(`${vue.$CMS_API_URL}/travel_components/fetch-travel-record-list.php?lang=${this.currLang}&rid=${this.id}`).then(
          (res)=>{
            if(res.data && res.data.data){
              this.responseData = res.data.data[0];
              this.responseData.imgs = this.responseData.img_arr.split(';');
              this.responseData.imgs.pop();
              for(var i = 0; i < this.responseData.imgs.length; i++){
                this.responseData.imgs[i] = `${vue.$S3_BUCKET_URL}/${this.responseData.imgs[i]}`
              }
              this.ready = true;
              this.mp3FilePath = `${vue.$S3_BUCKET_URL}/${this.responseData.audioFile}`
              console.log(this.responseData);
            }
          }
        )
      },
      toggleSound() {
        let audio = this.$refs.audio;
        if (
          audio.paused &&
          document.querySelector(".toggle-sound").classList.contains("paused")
        ) {
          console.log("play it")
          audio.play();
          document.querySelector(".toggle-sound").classList.remove("paused");
        } else {
          console.log("pause it")
          audio.pause();
          document.querySelector(".toggle-sound").classList.add("paused");
        }
      },
      isShowPrev(increment) {
        const currentIndex = this.itemIdList.indexOf(this.id)
        if (currentIndex < 0 || currentIndex + increment < 0) 
          return false
        return true
      },
      isShowNext(increment) {
        const currentIndex = this.itemIdList.indexOf(this.id)
        if (currentIndex < 0 || currentIndex + increment >= this.itemIdList.length) 
          return false
        return true
      },
      navigateToExhibition(increment) {
        const currentIndex = this.itemIdList.indexOf(this.id)
        if (currentIndex < 0 || currentIndex + increment >= this.itemIdList.length || currentIndex + increment < 0) 
          return
        console.log("Navigate to : ", this.itemIdList[currentIndex + increment])
        this.$router.push({
          name: 'OnlineExhibitionDetails',
          query: {
            id: this.itemIdList[currentIndex + increment]
          }
        });
      },
      fetchItemList() {
        this.itemIdList = [];
        axios.get(`${this.$CMS_API_URL}/travel_components/fetch-travel-record-list.php`).then(
          (res)=>{
            if(res.data && res.data.data){
              this.itemIdList = res.data.data.map(item => item.ref_id)
              console.log("this.itemIdList", this.itemIdList)
            }
          }
        )
      }
    }
  }
</script>
<style scoped>
  .introduction {
    font-size: 32px;
    color: rgb(220,157,96);
  }
  .separator {
    position: relative;
    border: none;
    height: 4px;
    background: white;
  }
  .body {
    font-size: 21.6px;
  }
  .image-container {
    text-align: center;
  }
  .image-container > img {
    width:100%;
    object-fit: cover;
  }
  .content {
    margin-top: 48px;
  }
  .voice-logo {
    width: 25%;
    cursor: pointer;
  }
  #audio {
    width: 100%;
    margin: 2em 0 0.5em 0;
  }
  .audio-wrapper  {
    text-align: center;
  }
  .introduction .field-name {
    width: 45%;
    display: inline-block;
  }
  .field-name, .field-content {
    font-size: 22.4px;
    word-wrap: break-word;
    max-width: 50%;
    word-wrap: break-word;
    vertical-align:top
  }
  .introduction .field-content {
    display: inline-block;
  }
  .slide {
    aspect-ratio: 4/3;
  }
  .slide img {
    background-color: #000;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width:992px){
    .offsetWrapper {
      padding-left:15px;
      padding-right: 15px;
    }
    .remark {
      font-size: 17.6px;
      padding-left:20px;
    }
  }
  @media (max-width:992px){
    .audio-wrapper {
      margin-bottom:2rem;
    }
    .audio-wrapper > img {
      width:50%;
    }
    .slide.mobile {
      width:80%;
    }
    .field-name , .field-content {
      font-size: 5.5vw;
    }
    .eng_inline {
      display:inline!important;
    }
    .body {
      font-size: 4.5vw;
    }
    hr.separator {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .nav-section {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
  }
  .nav-section .nav-button {
    font-size: 22.4px;
    cursor: pointer;
    user-select:none;
  }
  .nav-section .nav-button img {
    width: 200px;
  }
  @media (max-width:992px){
    .nav-section {
      padding: 12px 0px;
    }
    .nav-section .nav-button {
      font-size: 4.5vw;
    }
    .nav-section .nav-button img {
      width: 35vw;
    }
  }
</style>
<style>
  @media(max-width:992px){
    .remark {
      font-size: 3.7vw;
      margin-bottom: 1em;
      margin-top: 1em;
      text-align: center;
      width:100%;
    }
    .voice-logo {
      width: 35%!important;
      cursor: pointer;
    }
    audio {
      margin:0!important;
    }
    .inlineArrow .agile__actions {
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      right:0;
    }
    .inlineArrow .agile__list {
      width:80%;
      z-index:999;
      margin:auto;
    }
    .inlineArrow .agile__list img {
      width:100%;
      object-fit: contain;
    }
  }
</style>