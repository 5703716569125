<template>
  <div id="app" :lang="`${$t('header.language_code')}`">
    <Header />
    <router-view id="main"></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form_data: ["a", "b"],
    };
  },
};
</script>

<style>
@import "./assets/styles/bootstrap-4.css";
@font-face {
  font-family: "Noto Sans CJK TC";
  src: local("Merienda"),
    url(./assets/fonts/NotoSansCJKtc-Regular.otf) format("truetype");
}
#app {
  font-family: "Noto Sans CJK TC", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: #666;
  min-height: 100%;
}
#main {
  min-height: calc(100vh - 242px - 42px);
}
body {
  background-image: url("./assets/images/Blue_BG_dark.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  overflow: auto;
  font-size: 0.8rem;
}
html {
  overflow: hidden;
  height: 100%;
}
hr.c-hr {
  border: 4px solid #EC706D;
}
.title {
  font-size: 30px;
  background-color: #666;
  text-align: center;
  padding: 3px;
  font-weight: bold;
}
@media (max-width: 992px) {
  .title {
    font-size: 20px;
  }
}
.subtitle {
  font-size: 30px;
  text-align: center;
  margin-top: 26px;
  font-weight: bold;
}
@media (max-width: 992px) {
  .subtitle {
    font-size: 23px;
  }
}
.normal-text {
  font-size: 17px;
}
.title{color:#FFF;}
.btn-container {
  position: relative;
  text-align: center;
  color: #2f94b9;
  font-size: 28px;
  font-weight: bold;
}
.btn-container a {
  color: #2f94b9;
}
@media (max-width: 996px) {
  .btn-container {
    font-size: 25px;
  }
}
.centered {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.yellow-btn {
  width: 280px;
}
body{
  background-image: unset;
}
#vue-nav-top-desktop{
  width: 100%; top: 0; left: 0; right: 0; margin: auto; z-index: 1; background-color: rgba(255,255,255,0.7);
}
.vue-nav-top > .container{position: relative;}
#logo-container{
  width: 320px;
  padding-left:5px;
  display: inline-block;
}
#lang-nav-container{width: calc(100% - 320px);float:right;height: 90px;}
#feather-menu-container{filter:invert(100%);}
.main-logo{width: auto; height: 90px;}
.main-logo{scale: 1.3;transform-origin: center left;}
#vue-nav-links-mobile{display: none !important;}
.inner-container{width:80% !important;}
.lang-nav{margin-top:-12px;padding-right: 12px;}
#html-dom-form-framework > .container:not(#desktop-header):not(#mobile-header){max-width: 80%;}

@media (min-width:991px){
  body    .container {
    max-width: 1600px;
    width:100%;
  }
}

@media (max-width:1300px){
  #vue-nav-links-desktop{padding-left:100px;}
}
@media (max-width:1200px){
  .main-logo{height:80px;}
}
@media (max-width:640px){
  .main-logo{height:60px;}
}
@media (max-width:991px){
  #vue-nav-top-desktop{display: none !important;}
  .main-logo{height:60px;}
  .inner-container{width:100% !important;}
  #html-dom-form-framework > .container:not(#desktop-header):not(#mobile-header){max-width: unset;}
  #vue-nav-links-desktop{padding-left:0px;}
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
#vue-nav{position: relative;z-index: 2;}
.new-embed-container.embed-responsive iframe{
  position: relative !important;
  width: 100% !important;
}
.new-embed-container{aspect-ratio: 1.78;}
.new-embed-container.embed-responsive-16by9::before{display: none;}
body .agile__nav-button .main-logo{height:15px;}
div.pink-rounded-btn{color: white; margin-top: 3px; font-size: 22px; line-height: 24px; font-weight: normal;letter-spacing: 1px;}
html[lang="en"] div.pink-rounded-btn{letter-spacing: -1px;}
</style>
