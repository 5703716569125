<template>
    <div>
        <div>
            <div id="desktop-header" class="container d-none d-lg-block">
                <div class="title">
                    {{$t("aboutUs.title")}}
                </div>
            </div>
            <div id="mobile-header" class="d-block d-lg-none">
                <div class="title">
                    {{$t("aboutUs.title")}}
                </div>
            </div>
        </div>
        <div id="html-dom-form-framework" class="container">
            <div class="container about-us-main">
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main1")}}
                  <br/>
                  <br/>
                    {{$t("aboutUs.main2")}}
                  <br/>
                  <br/>
                    {{$t("aboutUs.main3")}}
                  <br/>
                  <br/>
                    {{$t("aboutUs.main4")}}
                </div>
                <!--
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main5")}}
                </div>
            -->
            </div>
        </div>
    </div>
</template>

<style>
.about-us-main{min-height: 800px;}
#html-dom-form-framework{text-align: justify;}
</style>
<script>
  export default {
    name: 'AboutUs',
    title: '小小博物館 KOL',
  }
</script>

<style scoped>
    .about-us-main {
        margin-bottom:64px;
    }
</style>