<template>
  <div>
    <div>
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{$t("header.bloopers")}}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{$t("header.bloopers")}}
        </div>
      </div>

      <div class="text-center d-lg-none d-md-none d-sm-block">
        <select class="year-tab-selector" @change="switchTab($event.target.value)">
          <option :selected="this.year === '2021'" value="2021">2021</option>
          <option :selected="this.year === '2022'" value="2022">2022</option>
          <option :selected="this.year === '2023'" value="2023">2023</option>
          <option :selected="this.year === '2024'" value="2024">2024</option>
        </select>
      </div>
      <div class="year-tab d-lg-block d-md-block d-sm-none d-none">
        <strong @click="switchTab('2021')" :class="{ selected: this.year === '2021' }">2021</strong>
        <span>|</span>
        <strong @click="switchTab('2022')" :class="{ selected: this.year === '2022' }">2022</strong>
        <span>|</span>
        <strong @click="switchTab('2023')" :class="{ selected: this.year === '2023' }">2023</strong>
        <span>|</span>
        <strong @click="switchTab('2024')" :class="{ selected: this.year === '2024' }">2024</strong>
      </div>
      <div id="html-dom-form-framework">
        <div id="bloopers" class="container">
        <div class="row justify-content-md-center">
          <template v-for="(blooper, index) in blooperList">
            <div :key="blooper.id" class="col-12 col-lg-6 col-md-6 col-sm-12">
              <router-link class="item no-decoration" :to="{ name: 'BlooperDetails', query: { id: blooper.ref_id } }">
                <div
                    class="item-grid d-lg-block"
                    :style="{
                  backgroundImage:
                    'url(' + blooper['cover-img'] + ')',
                }"
                >
                  <div class="title">
                    {{blooper.title}}
                    <div class="normal-text section-description">
                      <div v-html="blooper['preview-text']"></div>
                    </div>
                  </div>

                </div>
              </router-link>
            </div>
          </template>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "Bloopers",
    title: "小小博物館 KOL",
    data() {
      return {
          blooperList: [],
          year: "2024"
      }
    },
    methods: {
      switchTab: function(year) {
        this.year = year
        this.getBloopersList()
      },
      getBloopersList: function() {
        let vue = this
        vue.blooperList = []

        // Get current locale
        let locale = "tc";
        if (["sc","tc","en"].includes(vue.$i18n.locale))
            locale = vue.$i18n.locale;

        // FIre HTTP Request to CMS Server
        axios({
          method: "get",
          url : `${vue.$CMS_API_URL}/events_components/events-features-overview.php?lang=${locale.toUpperCase()}&year=${this.year}`
        }).then((response) => {
          console.log("response", response.data);
          if (response.data && response.data.data) {
            vue.blooperList = response.data.data.map(blooper => {
              return Object.assign(blooper, {
                'cover-img': `${vue.$S3_BUCKET_URL}/${blooper['cover-img']}`
              });
            });
            console.log("vue.blooperList", vue.blooperList);
          }
        }).catch(function(error){
            console.log('erros : ',error);
        });
      }
    },
    mounted: function() {
      this.getBloopersList();
    },
    watch: {
      '$i18n.locale': function() {
        this.getBloopersList();
      }
    }
  };
</script>

<style scoped>
  #bloopers {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .section-header {
    text-align: center;
    margin-bottom: 48px;
  }
  .section-header .title {
    padding: 12px;
    background-color: #EA9852;
  }
  .section-image {

  }
  .section-description {
    margin-top: 24px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    color:white;
    display: block;;
  }
  .item:hover .section-description{
    max-height: 500px;
  }
  hr.c-hr {
    border: 4px solid;
    border-color: #ffffff; 
    margin: 36px 0px;
  }
  .year-tab {
    text-align: center;
    margin-top: 40px;
    max-width: 100%;
    overflow-x: auto;
  }
  .year-tab span {
    font-size: 2rem;
    margin: 0 12px;
    cursor: pointer;
  }
  .year-tab span.selected {
    color: #EC706D;
    font-weight: bold;
    text-decoration: underline;
  }
  .year-tab strong {
    font-size: 2rem;
    margin: 0 12px;
    cursor: pointer;
    font-weight: normal;
  }
  .year-tab strong.selected {
    color: #EC706D;
    font-weight: bold;
    text-decoration: underline;
  }
  .item{position:relative;display: block;width:100%;padding:1%;box-sizing: border-box;}
  .item-grid{display:block;width:100%;aspect-ratio: 1.5;position: relative;background-repeat: no-repeat;background-size:cover;background-position:center center;}
  .item .title{position:absolute;bottom:0;width:100%;box-sizing:border-box;padding:1% 5%;background-color:rgba(236, 112, 109,0.7);}
  .year-tab-selector{color: #eb706d; background-color: #F6F7FB;font-size: 20px;font-weight: bold;margin-top: 40px;text-align-last:center;width: 50%;}
</style>

<style>
  .no-decoration, .no-decoration:hover {
    text-decoration: none;
    color: unset;
  }
</style>