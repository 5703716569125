<template>
  <div>
    <div id="ordinance" name="ordinance">
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{$t("tandc.privacyTitle")}}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{$t("tandc.privacyTitle")}}
        </div>
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div class="container normal-text" style="margin-top: 24px">
        <ol>
          <li>{{$t("tandc.ordinance1")}}</li>
          <li>{{$t("tandc.ordinance2")}}</li>
          <li>{{$t("tandc.ordinance3")}}</li>
          <li>{{$t("tandc.ordinance4")}}</li>
          <li>{{$t("tandc.ordinance5")}}</li>
          <li>{{$t("tandc.ordinance6")}}</li>
          <li>{{$t("tandc.ordinance7")}}</li>
          <li>{{$t("tandc.ordinance8")}}</li>
          <li>{{$t("tandc.ordinance9")}}</li>
          <li>{{$t("tandc.ordinance10")}}</li>
          <li>{{$t("tandc.ordinance11")}}</li>
          <li>{{$t("tandc.ordinance12")}}</li>
          <li>{{$t("tandc.ordinance13")}}</li>
          <li>{{$t("tandc.ordinance14")}}</li>
          <li>{{$t("tandc.ordinance15")}}</li>
          <li>{{$t("tandc.ordinance16")}}</li>
          <li>{{$t("tandc.ordinance17")}}</li>
          <li>{{$t("tandc.ordinance18")}}</li>
          <li>{{$t("tandc.ordinance19")}}</li>
          <li>{{$t("tandc.ordinance20")}}</li>
          <li>{{$t("tandc.ordinance21")}}</li>
          <li>{{$t("tandc.ordinance22")}}</li>
          <li>{{$t("tandc.ordinance23")}}</li>
        </ol>
      </div> 
      <!-- <div class="title" style="margin-top: 24px">
        {{$t("tandc.contactTitle")}}
      </div>
      <div id="contactUs" name="contactUs" class="container normal-text" style="margin-top: 24px; margin-bottom: 24px;">
        <div><a href="mainto:event@ohpama.com">event@ohpama.com</a></div>
      </div> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactUsAndOrdinace',
    title: '小小博物館 KOL',
  }
</script>

<style scoped>
  a {
    color: #ffffff;
  }
  #html-dom-form-framework{padding-bottom:100px;}
</style>