<template>
  <div>
    <div id="ordinance" name="ordinance">
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{$t("footer.sitemap")}}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{$t("footer.sitemap")}}
        </div>
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div class="container normal-text" style="margin-top: 24px;padding-bottom: 50px;">
        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/`"
              >{{ $t("header.home") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/about-us`"
              >{{ $t("header.aboutUs") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/events`"
              >{{ $t("header.kolProgramme") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/event-highlights`"
              >{{ $t("header.bloopers") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/kol-video`"
              >{{ $t("header.kolVid") }}</a>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12">
              <a
                  class="sitemap-link-sub"
                  :href="`/${this.$i18n.locale}/kol-video-list?type=1`"
              >{{ $t("kolVid.themeTitle1") }}</a>

              <a
                  class="sitemap-link-sub"
                  :href="`/${this.$i18n.locale}/kol-video-list?type=2`"
              >{{ $t("kolVid.themeTitle2") }}</a>

              <a
                  class="sitemap-link-sub"
                  :href="`/${this.$i18n.locale}/kol-video-list?type=3`"
              >{{ $t("kolVid.themeTitle3") }}</a>

            </div>
          </div>
        </div>



        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/online-exhibition`"
              >{{ $t("header.onlineExhibition") }}</a>
            </div>
          </div>
        </div>


        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/kol-sharing`"
              >{{ $t("header.KolSharing") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/news`"
              >{{ $t("header.highlights") }}</a>
            </div>
<!--            <div class="col-lg-8 col-md-12 col-sm-12">-->
<!--              <a-->
<!--                  class="sitemap-link-sub"-->
<!--                  target="_blank"-->
<!--                  aria-describedby="This will open a new window"-->
<!--                  href="https://www.museums.gov.hk/tc/web/portal/kids-and-family-art.html"-->
<!--              >{{ $t("museumSelect.art") }}</a>-->



<!--              <a-->
<!--                  class="sitemap-link-sub"-->
<!--                  target="_blank"-->
<!--                  aria-describedby="This will open a new window"-->
<!--                  href="https://www.museums.gov.hk/tc/web/portal/kids-and-family-history-and-culture.html"-->
<!--              >{{ $t("museumSelect.historyAndCulture") }}</a>-->
<!--              <a-->
<!--                  class="sitemap-link-sub"-->
<!--                  target="_blank"-->
<!--                  aria-describedby="This will open a new window"-->
<!--                  href="https://www.museums.gov.hk/tc/web/portal/kids-and-family-science.html"-->
<!--              >{{ $t("museumSelect.science") }}</a>-->
<!--            </div>-->
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/contact-us`"
              >{{ $t("header.contactUs") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/terms-and-condition`"
              >{{ $t("footer.terms") }}</a>
            </div>
          </div>
        </div>


        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  href="https://www.lcsd.gov.hk/tc/info_pdo.html"
              >{{ $t("footer.privacy") }}</a>
            </div>
          </div>
        </div>

        <div class="sitemap-link-container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <a
                  class="sitemap-link"
                  :href="`/${this.$i18n.locale}/sitemap`"
              >{{ $t("footer.sitemap") }}</a>
            </div>
          </div>
        </div>


      </div> 
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Sitemap',
    title: '小小博物館 KOL',
  }
</script>

<style scoped>
  .sitemap-link-container{
    border-bottom: solid #EC706D 3px;
    padding: 15px;
  }
  .sitemap-link{
    color: #EC706D;
    font-size: 2rem;
    font-weight: bold;
  }
  .sitemap-link-sub{width:50%;display: inline-block;color:#666;font-weight: bold;line-height: 40px;
    font-size: 24px;}
  .sitemap-link-sub:before{content: '・';}
  @media(max-width: 640px) {
    .sitemap-link-sub{width:100%;display: block;}
    .sitemap-link{font-size: 20px;}
    .sitemap-link-sub{font-size:18px;}
  }
</style>